
import { Component, Vue, Prop } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";
import UiRadioButton from "@/components/Main/Ui/UiRadioButton.vue";
import {
  dispatchChangeEmailViaAuthApp,
  dispatchConfirmMyNewEmail,
  dispatchLogOut,
  dispatchRequestUpdateUserEmail
} from "@/store/main/actions";
import {dispAuthFetch} from "@/store/mlm/actions";

@Component({
  components: {UiRadioButton},
})
export default class ChangeEmailModal extends Vue {
  // @ts-ignore
  @Prop({ required: true, default: false }) public readonly modalShow: boolean;

  public isModalClosed: boolean = false;
  public selectedVariable = ""
  public currentStep = '1'
  public newEmail = ''
  public otpCode = ''
  public errorMessage = ''

  public token?: any = ''
  public email?: any = ''

  public async mounted() {}

  public async changeEmail(){
    const result = await dispatchRequestUpdateUserEmail(this.$store, {
      new_email: this.newEmail,
    });
    if (result) {
      this.$toast.success(this.$t("Email change letter sent").toString());
      return true;
    } else {
      this.$toast.error(this.$t("Error sending email to change email").toString());
      return false;
    }
  }

  public async changeEmailFromMfa() {
    const result = await dispatchChangeEmailViaAuthApp(this.$store, {
      new_email: this.newEmail,
      code: this.otpCode,
    });
    return  result;
  }

  public async finish() {
    await dispatchLogOut(this.$store)
  }

  public closeModal() {
    this.currentStep = '1';
    this.isModalClosed = true;
    this.$emit("close-modal");
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  prev(step) {
    if(step)
      this.currentStep = step
  }

  async next(step) {
    this.errorMessage = ''
    if(step == '1.2'){
      if(await this.changeEmail()) {
        //this.currentStep = step
        this.closeModal()
      }
    } else if(step == '2.1'){
      this.currentStep = step
    } else if(step == '2.2'){
      this.currentStep = step
    } else if(step == '3'){
      const result = await this.changeEmailFromMfa()
      if (result.status === 200) {
        this.$toast.success(this.$t("Email changed").toString());
        this.currentStep = '3'
      } else {
        this.$toast.error(this.$t("Email has not been changed").toString());
        if(result.response.data.new_email){
          this.currentStep = '2.1'
          this.errorMessage = this.$t("Invalid email").toString()
        } else if(result.response.data.code) {
          this.currentStep = '2.2'
          this.errorMessage = this.$t("mfa.invalid_code").toString()
        }
        this.otpCode = ''
      }
    } else if(step != null){
      this.currentStep = step
    } else if(this.selectedVariable == 'Email') {
      this.currentStep = '1.1'
    } else if(this.selectedVariable == 'OTP') {
      this.currentStep = '2.1'
    }
  }
}
