var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"popup",class:{'popup_active': _vm.modalShow}},[_c('div',{staticClass:"popup__container popup__container_type_main"},[_c('button',{staticClass:"popup__exit",attrs:{"type":"button"},on:{"click":_vm.closeModal}}),_c('div',{staticClass:"form popup__form"},[_c('div',{staticClass:"mb-4"},[_vm._m(0),_c('ul',{staticClass:"list-disc"},[_c('li',{staticClass:"list-disc mb-2 ml-4"},[_vm._v(_vm._s(_vm.$t("Minimum withdrawal"))+" "),_vm._m(1)]),_c('li',{staticClass:"list-disc mb-2 ml-4"},[_vm._v(_vm._s(_vm.$t("Maximum daily withdrawal"))+" "),_vm._m(2)]),_c('li',{staticClass:"list-disc mb-2 ml-4"},[_vm._v(_vm._s(_vm.$t("Maximum number of USDT withdrawals per day"))+" "),_vm._m(3)]),_c('li',{staticClass:"list-disc mb-2 ml-4"},[_vm._v(_vm._s(_vm.$t("USDT withdrawal fee"))+" "),_vm._m(4)])]),_vm._m(5),_c('ul',[_c('li',{staticClass:"list-disc mb-2 ml-4"},[_vm._v(_vm._s(_vm.$t("Minimum withdrawal"))+" "),_vm._m(6)]),_c('li',{staticClass:"list-disc mb-2 ml-4"},[_vm._v(_vm._s(_vm.$t("Maximum withdrawal"))+" "),_vm._m(7)]),_c('li',{staticClass:"list-disc mb-2 ml-4"},[_vm._v(_vm._s(_vm.$t("Maximum number of DexToken withdrawals per day"))+" "),_vm._m(8)]),_c('li',{staticClass:"list-disc mb-2 ml-4"},[_vm._v(_vm._s(_vm.$t("DexToken withdrawal fee"))+" "),_vm._m(9)])])]),_c('button',{staticClass:"form__btn",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v(_vm._s(_vm.$t('Close')))])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"mb-2"},[_c('b',[_vm._v("USDT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('span',{staticStyle:{"color":"#00FFF0"}},[_vm._v("35 USDT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('span',{staticStyle:{"color":"#00FFF0"}},[_vm._v("6000 USDT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('span',{staticStyle:{"color":"#00FFF0"}},[_vm._v("2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('span',{staticStyle:{"color":"#00FFF0"}},[_vm._v("2%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"mt-4 mb-2"},[_c('b',[_vm._v("DexToken")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('span',{staticStyle:{"color":"#00FFF0"}},[_vm._v("5 DexToken")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('span',{staticStyle:{"color":"#00FFF0"}},[_vm._v("no limit")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('span',{staticStyle:{"color":"#00FFF0"}},[_vm._v("2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('span',{staticStyle:{"color":"#00FFF0"}},[_vm._v("1%")])])
}]

export { render, staticRenderFns }