
import { Component, Vue, Prop } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";
import UiRadioButton from "@/components/Main/Ui/UiRadioButton.vue";
import {
  dispatchChangeEmailViaAuthApp,
  dispatchConfirmMyNewEmail, dispatchCreateRequestDexNewYearPromo, dispatchGetUsdtDexnetRate,
  dispatchLogOut,
  dispatchRequestUpdateUserEmail
} from "@/store/main/actions";
import {dispAuthFetch} from "@/store/mlm/actions";
import axios from "axios";
import {apiUrl} from "@/env";

@Component({
  components: {UiRadioButton},
})
export default class ByTariffFromDexNetTokenModal extends Vue {
  @Prop({ required: true }) public readonly modalShow!: boolean;
  @Prop({ default: 0 }) public readonly price!: number;
  @Prop({ default: null }) public readonly package!: any;

  public isModalClosed: boolean = false;
  public address = "0x3d9a44250cd30c4a13974aa623177d824b241427"
  public transaction_hash = ""
  public errorMessage = ''
  public currentStep = '1'
  public priceDexNet = 0
  public async mounted() {
    const responce = await dispatchGetUsdtDexnetRate(this.$store,
        { amount: this.price })
    console.log('usdt_dexnet_rate')
    this.priceDexNet = responce.data.convertated_amount
    console.log(responce)
  }

  public closeModal() {
    this.isModalClosed = true;
    this.currentStep == '1'
    this.$emit("close-modal");
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  async confirmPayment() {
    this.errorMessage = ''

    if(this.currentStep == '1'){
      if(this.transaction_hash.length == 0) {
        this.errorMessage = this.$t('Enter transaction hash to verify').toString();
        return;
      };
      this.currentStep = '2'
      console.log('this.package', this.package)
      await dispatchCreateRequestDexNewYearPromo(this.$store, {
        package: this.package.id,
        usdt_amount: this.price,
        dexnet_amount: this.priceDexNet,
        transaction_hash: this.transaction_hash,
      })
    } else {
      this.closeModal()
    }
  }

  copyToClipboard(value) {
    this.$copyText(value.toString()).then((e) => {
      // @ts-ignore
      this.$toast.success(this.$t('Copied successfully'), {
        duration: 2000, position: 'bottom',
      });
    }, (e) => {
      // @ts-ignore
      this.$toast.error(this.$t('Error, unable to copy'), {
        duration: 2000, position: 'bottom',
      });
    });
  }
}
