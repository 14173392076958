var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"popup",class:{ popup_active: _vm.modalShow }},[_c('div',{staticClass:"popup__container"},[_c('button',{staticClass:"popup__exit",attrs:{"type":"button"},on:{"click":_vm.closeModal}}),(_vm.activePackage && _vm.activePackage.active && _vm.pack.upgrade)?_c('div',{staticClass:"popup__titleblock"},[_c('div',{staticClass:"popup__text"},[_c('h2',{staticClass:"popup__title"},[_vm._v(" "+_vm._s(_vm.$t("Upgrade to"))+" "),_c('br'),_vm._v(_vm._s(_vm.$t("Tariff"))+" "+_vm._s(_vm.pack.name)+" ")])]),_c('div',{staticClass:"popup__price"},[_c('p',{staticClass:"popup__price-text"},[_vm._v(_vm._s(_vm.$t("Upgrade price")))]),_c('p',{staticClass:"popup__coast"},[_vm._v(" "+_vm._s(Number(_vm.pack.price).toFixed(0))+" "),_c('span',{staticClass:"popup__usd"},[_vm._v("USDT")])])])]):_c('div',{staticClass:"popup__titleblock"},[_c('div',{staticClass:"popup__text"},[_c('h2',{staticClass:"popup__title"},[_vm._v(_vm._s(_vm.$t("Pay order")))]),_c('p',{staticClass:"popup__subtitle"},[_vm._v(" "+_vm._s(_vm.$t("package_purchase_message"))+" ")])]),_c('div',{staticClass:"popup__price"},[_c('p',{staticClass:"popup__coast"},[_vm._v(_vm._s(Number(_vm.pack.price).toFixed(0)))]),_c('p',{staticClass:"popup__usd"},[_vm._v("USDT")])])]),_c('div',{staticClass:"popup__types-pay"},[_c('div',{staticClass:"popup__item",class:_vm.paymentMethod === 'wallet' && 'popup__item_checked'},[_c('label',{staticClass:"form-checkbox popup__item-checkbox",style:(_vm.invoiceResponse &&
              _vm.userProfile &&
              Number(_vm.userProfile.balances.units_total) <
                Number(_vm.invoiceResponse.crypto_amount)
                ? 'pointer-events: none;'
                : ''),on:{"click":function($event){_vm.paymentMethod = 'wallet'}}},[_c('input',{staticClass:"form-checkbox__input",attrs:{"type":"radio"},domProps:{"checked":_vm.paymentMethod === 'wallet'}}),_c('span',{staticClass:"form-checkbox__text"},[_vm._v(_vm._s(_vm.$t("Pay from internal balance")))])]),_c('p',{staticClass:"popup__item-price text-black",class:{
              'color-red':
                _vm.invoiceResponse &&
                _vm.userProfile &&
                Number(_vm.userProfile.balances.units_total) <
                  Number(_vm.invoiceResponse.crypto_amount),
              'color-blue':
                _vm.invoiceResponse &&
                _vm.userProfile &&
                Number(_vm.userProfile.balances.units_total) >=
                  Number(_vm.invoiceResponse.crypto_amount),
            }},[_vm._v(" "+_vm._s(_vm.userProfile ? _vm.userProfile.balances.units_total : 0)+" USDT ")])]),(Number(_vm.pack.price) >= 1500)?_c('p',{staticClass:"popup__info-text",staticStyle:{"margin-top":"16px"}},[_vm._v(" "+_vm._s(_vm.$t( "Upon delivery of goods, the client pays customs duties and delivery costs independently." ))+" ")]):_vm._e()]),_c('div',{staticClass:"popup__btns-up"},[(_vm.invoiceDetail && _vm.invoiceDetail.status === 'CANCELED')?_c('p',{staticClass:"popup__up-error"},[_vm._v(" "+_vm._s(_vm.$t("You cannot purchase this tariff. It's already purchased"))+" ")]):(
            _vm.invoiceResponse &&
            _vm.userProfile &&
            Number(_vm.userProfile.balances.units_total) <
              Number(_vm.invoiceResponse.crypto_amount)
            && (_vm.paymentMethod !== 'DexNetToken')
          )?_c('div',{staticClass:"insufficent-funds"},[_c('p',{staticClass:"popup__up-error"},[_vm._v(" "+_vm._s(_vm.$t("Insufficient funds on the internal balance"))+" ")]),_c('router-link',{staticStyle:{"width":"100%"},attrs:{"to":"/transactions?top_up=true"}},[_c('button',{staticClass:"popup__btn",staticStyle:{"width":"100%"},attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.$t("Deposit"))+" "),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingState.submit),expression:"loadingState.submit"}],attrs:{"small":""}})],1)])],1):(
            _vm.invoiceResponse &&
            _vm.userProfile &&
            (Number(_vm.userProfile.balances.units_total) >=
              Number(_vm.invoiceResponse.crypto_amount))
            || (_vm.paymentMethod === 'DexNetToken')
          )?_c('div',[_c('button',{staticClass:"popup__btn",attrs:{"type":"button","disabled":_vm.loadingState.submit},on:{"click":_vm.handleProcessPayment}},[_vm._v(" "+_vm._s(_vm.$t("Confirm"))+" "),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingState.submit),expression:"loadingState.submit"}],attrs:{"small":""}})],1)]):_c('div',[_c('button',{staticClass:"popup__btn btn-disabled",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.$t("Confirm"))+" "),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingState.submit),expression:"loadingState.submit"}],attrs:{"small":""}})],1)])]),_c('ByTariffFromDexNetTokenModal',{attrs:{"package":_vm.pack,"price":Number(_vm.pack.price).toFixed(0),"modalShow":_vm.isShowModalByTariffFromDexNetToken},on:{"close-modal":function($event){_vm.isShowModalByTariffFromDexNetToken = false; _vm.closeModal()}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }