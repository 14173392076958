

<template>
  <div v-if="user?.language != null">
    <date-picker
        ref="datePicker"
        v-model="dateRange"
        type="daterange"
        range-separator=" - "
        :range="true"
        :multiple="false"
        start-placeholder="Начало"
        end-placeholder="Конец"
        :lang="user?.language === 'ru' ? ru : en"
        :format="'DD-MM-YYYY'"
        @change="onDateChange" />
  </div>

<!--  {{ $root.$i18n.locale }}-->
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import en from 'vue2-datepicker/locale/en';
import ru from 'vue2-datepicker/locale/ru';
import { readUserProfile } from '@/store/main/getters';


export default {
  name: "UiCalendar",
  components: {
    DatePicker,
  },

  data() {
    return {
      dateRange: null, // Здесь сохраняются выбранные даты
      en: en,
      ru: ru,
      user: null,
    };
  },

  props: {

  },

  mounted() {
    this.user = readUserProfile(this.$store);
    console.log(this.user?.language)
    // Пример применения локализации
    // this.$refs.datePicker.locale = ru;
  },

  computed: {
    formattedDateRange() {
      if (this.dateRange) {
        return `${this.dateRange[0]} - ${this.dateRange[1]}`;
      }
      return '';
    },
  },

  methods: {
    onDateChange(newDateRange) {
      this.$emit('date-changed', newDateRange.map(date => this.formatDate(date)));
    },

    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }
  }
};
</script>
<style>
.mx-datepicker-range {
  width: 100%;
  background: #292929;
  border: none;
  padding: 15px 17px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.4);
}

.mx-calendar.mx-calendar-panel-date:nth-child(2) {
  display: none;
}

.mx-calendar-header-label {
  display: flex;
  justify-content: center;
}

.mx-table.mx-table-date thead tr {
  display: flex;
}

.mx-table.mx-table-date thead tr th {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  background-color: #00FFF0;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  color: black;
  padding: 0 5px;
  margin: 0 2px;
}

.mx-table.mx-table-date tbody tr{
  display: flex;
  justify-content: space-around;
  flex-basis: 40px;
}

.mx-table.mx-table-date tbody tr td {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 2px;
  border: 2px solid transparent;
}

.mx-calendar-content .cell:hover, .mx-calendar-content .cell.in-range, .mx-calendar-content .cell.hover-in-range {
  background-color: #00FFF0 !important;
}

.mx-calendar-content.cell.active, .cell.in-range, .cell.active {
  background-color: transparent !important;
  border: 2px solid #00FFF0 !important;;
}
</style>