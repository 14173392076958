
import { Component, Vue } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";
import {
  dispatchGetUserProfile,
  dispatchUnsubscribeFromBot,
  dispatchUpdateUserAvatar,
  dispatchUpdateUserProfile,
} from "@/store/main/actions";

import ConnectMFAModal from "./ConnectMFAModal.vue";
import ChangeEmailModal from "@/components/Main/ChangeEmailModal.vue";

@Component({
  components: {
    ChangeEmailModal,
    ConnectMFAModal,
  },
})
export default class UserProfileEdit extends Vue {
  public imageFile: any = null;

  public avatarModalShow: boolean = false;
  public mfaModalShow: boolean = false;
  public financePasswordModalShow: boolean = false;
  public telegramFormShow: boolean = false;
  public credentialsFormShow: boolean = false;
  public fName: any = "";
  public lName: any = "";
  public tgName: any = "";
  public showСontactInfo: boolean = false;
  public emailFormShow: boolean = false;

  public mounted() {
    if(this.$route.query.token && this.$route.query.email) {
      this.emailFormShow = true
    }

    // @ts-ignore
    this.showСontactInfo = this.userProfile?.show_contact_info
      ? this.userProfile?.show_contact_info
      : false;
  }

  public handleConnectMfa() {
    if (this.userProfile?.is_mfa_enabled === true) return;
    this.mfaModalShow = true;
  }

  public async handleCloseMfa() {
    this.mfaModalShow = false;
    await dispatchGetUserProfile(this.$store);
  }

  public handleOpenCredentials() {
    this.credentialsFormShow = true;
    this.fName = this.userProfile?.first_name;
    this.lName = this.userProfile?.last_name ? this.userProfile?.last_name : "";
  }

  public async handleSubmitCredentials() {
    const result = await dispatchUpdateUserProfile(this.$store, {
      email: this.userProfile?.email,
      first_name: this.fName,
      last_name: this.lName,
    });
    if (result) {
      // dispatchGetUserProfile(this.$store);
      this.$toast.success(this.$t("Saved successfully").toString());
    } else {
      this.$toast.error(this.$t("Failed to save").toString());
    }
    this.credentialsFormShow = false;
  }

  public handleChangeEmail(){
    this.emailFormShow = true;
  }

  public handleChangeTelegram() {
    this.telegramFormShow = true;
    // @ts-ignore
    this.tgName = this.userProfile?.tg_username
      ? this.userProfile?.tg_username
      : "";
  }

  public async handleSubmitChangeTelegram() {
    const result = await dispatchUpdateUserProfile(this.$store, {
      email: this.userProfile?.email,
      tg_username: this.tgName,
    });
    if (result) {
      // dispatchGetUserProfile(this.$store);
      this.$toast.success(this.$t("Saved successfully").toString());
    } else {
      this.$toast.error(this.$t("Failed to save").toString());
    }
    this.telegramFormShow = false;
  }

  public async handleChangeCheckbox() {
    console.log(this.showСontactInfo);
    const result = await dispatchUpdateUserProfile(this.$store, {
      email: this.userProfile?.email,
      show_contact_info: this.showСontactInfo,
    });
    if (result) {
      this.$toast.success(this.$t("Saved successfully").toString());
    } else {
      this.$toast.error(this.$t("Failed to save").toString());
    }
  }

  public handleConnectBot() {
    if (
      this.userProfile &&
      this.userProfile.telegramuser &&
      this.userProfile.telegramuser.telegram_link
    ) {
      setTimeout(() => {
        dispatchGetUserProfile(this.$store);
      }, 30000);
      window.open(this.userProfile.telegramuser.telegram_link, "__blank");
    } else {
      this.$toast.error(this.$t("Failed").toString());
    }
  }

  public async handleDisableBot() {
    if (confirm(this.$t("Do you really want to disable bot?").toString())) {
      const result = await dispatchUnsubscribeFromBot(this.$store);
      if (result) {
        setTimeout(() => {
          dispatchGetUserProfile(this.$store);
        }, 30000);
        this.$toast.success(
          this.$t("Go to bot to confirm unsubscribe").toString()
        );
      } else {
        this.$toast.error(this.$t("Failed to save").toString());
      }
    }
  }

  public handleLoadAvatar() {
    if (this.$refs.myFileInput) {
      // @ts-ignore
      this.$refs.myFileInput.click();
    }
  }

  public handleOpenAvatarModal() {
    this.avatarModalShow = true;
  }

  public handleCloseAvatarModal() {
    this.avatarModalShow = false;
  }

  public async handleChangeImage(event) {
    // this.imageFile = event.target.files ? event.target.files[0] : '';
    const formData = new FormData();
    formData.append("avatar", event.target.files ? event.target.files[0] : "");
    await dispatchUpdateUserAvatar(this.$store, formData);
    this.handleCloseAvatarModal();
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get getImageUrl() {
    // if (!this.imageFile && this.userProfile && this.userProfile.avatar_165) {
    //   return `background-image: url(${this.userProfile.avatar_165});`;
    if (this.imageFile) {
      return URL.createObjectURL(this.imageFile);
    } else {
      return "";
    }
  }
}
