
import { Component, Vue } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";
import UiRadioButton from "@/components/Main/Ui/UiRadioButton.vue";
import { dispatchConfirmMyNewEmail, dispatchLogOut } from "@/store/main/actions";

@Component({
  components: {UiRadioButton},
})
export default class ConfirmEmailView extends Vue {
  public currentStep = '1'
  public token?: any = ''
  public email?: any = ''

  public async mounted() {
    if(this.$route.query.token && this.$route.query.email) {
      this.token = this.$route.query?.token
      this.email = this.$route.query?.email
      await this.confirmEmail()
      this.currentStep = '3'
    } else {
      this.$router.push('/');
    }
  }

  public async confirmEmail(){
    const result = await dispatchConfirmMyNewEmail(this.$store, { email: this.email, token: this.token });
    if (result) {
      this.$toast.success(this.$t("Email changed").toString());
      return true;
    } else {
      this.$toast.error(this.$t("Email has not been changed").toString());
      return false;
    }
  }

  public async finish() {
    await dispatchLogOut(this.$store)
  }

  public closeModal() {
    this.$router.push('/');
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }
}
