<template>
  <label class="checkbox">
    <div class="checkbox-box" :class="isChecked ? 'checkbox-box-active' : ''"></div>
    <input class="checkbox-value" type="radio" :checked="isChecked" :value="value" @change="$emit('change', $event.target.value)" />
    <span class="checkbox-text"><slot></slot></span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    "modelValue": {default: ""},
    "value": {type: String, default: undefined}
  },
  computed: {
    isChecked() {
      return this.modelValue == this.value
    }
  }
}
</script>
<style scoped>
.checkbox {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-box {
  position: relative;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #00e5c0;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  margin-right: 20px;
  transition: background-color 0.2s ease, transform 0.2s ease;
  flex-shrink: 0;
}

.checkbox-box-active::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7px;
  height: 7px;
  background-color: #00e5c0;
  border-radius: 3px;
  transform: translate(-50%, -50%);
}

.checkbox-value {
  display: none;
}

.checkbox-text {
  white-space: nowrap;
}
</style>