
import {readActivePackage, readPackagesList} from "@/store/mlm/getters";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class MyPromocodeModal extends Vue {
  // @ts-ignore
  @Prop({ required: true, default: false }) public readonly modalShow: boolean;
  // @ts-ignore
  // @Prop({required: true}) readonly public package: IPackage;
  public loadingState: any = {
    submit: false,
  };

  public closeModal(newPackage) {
    this.$emit("close-modal", newPackage);
  }
  get activePackage() {
    return readActivePackage(this.$store);
  }

  get listPackages() {
    return readPackagesList(this.$store)
  }

  public findNextTariff(currentTariff, tariffs) {
    // Сортируем тарифы по возрастанию цены
    const sortedTariffs = tariffs.slice().sort((a, b) => a.price - b.price);

    // Ищем текущий тариф в отсортированном массиве
    for (let i = 0; i < sortedTariffs.length; i++) {
      if (sortedTariffs[i].id === currentTariff.id) {
        // Возвращаем следующий тариф, если он существует
        return sortedTariffs[i + 1] || null;
      }
    }
    return null; // Если текущий тариф не найден или он последний
  }
}
